import React from 'react'
import pic1 from '../img/1 pic.jpeg'

const Header = () => {
  return (
    <>
    <div className='hero' id='Home'>
      {/* <div className='row'> */}
        {/* <div className='col-lg'> */}


        <img src={pic1} className='img-fluid' alt='pic'/>
        
        </div>
      {/* </div> */}

    {/* </div> */}
    </>
  )
}

export default Header;